import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { locale } from './utils';
import { DatePickerInstance } from './types';
import { WithFormControlProps, InputRef } from 'bundles/hocs/withFormControl/types';
import { i18n } from 'bundles/utils';

registerLocale('locale', locale);

const DatePickerComponent = React.forwardRef((props: WithFormControlProps, ref: InputRef) => {
  const { placeholder = i18n.t('labels.date_format'), dateFormat = 'yyyy-MM-dd', monthsShown = 2, ...rest } = props.rest;

  const handleChangeRaw = (value: string) => value?.length === 10 && props.onChange(new Date(value));

  return (
    <DatePicker
      placeholderText={placeholder}
      locale="locale"
      dateFormat={dateFormat}
      autoComplete="off"
      monthsShown={monthsShown}
      onChange={(v: string) => props.onChange(v)}
      selected={props.value ? new Date(props.value) : null}
      onChangeRaw={(event: KeyboardEvent & { target: HTMLInputElement }) => handleChangeRaw(event.target.value)}
      {...rest}
      ref={(instance: DatePickerInstance) => (instance && ref(instance.input))}
    />
  );
});

DatePickerComponent.displayName = 'Datepicker';

export default DatePickerComponent;
